
.filter {
    z-index: 2;
    position: sticky;
    top: $header-height;
    background-color: #fff;
    @include box-shadow(0px 1px 3px #00000029);
}

.filter__content {
    padding: 20px 0;

    @media screen and (max-width: 425px) {
        padding: 15px 0;
    }
}

.filter__form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -7px -20px;

    @media screen and (max-width: 900px) {
        margin: -7px -15px;
    }
    @media screen and (max-width: 768px) {
        margin: -7px;
    }
    @media screen and (max-width: 425px) {
        margin: -5px;
    }
}

.filter__input-wrapper {
    margin: 7px 20px;

    @media screen and (max-width: 900px) {
        margin: 7px 15px;
    }
    @media screen and (max-width: 768px) {
        margin: 7px;
    }
    @media screen and (max-width: 425px) {
        margin: 5px;
    }
}

.filter__input-label {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    min-width: 105px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
    letter-spacing: .48px;
    text-transform: uppercase;
    border: 1px solid #0000004D;
    border-radius: 5px;

    @media screen and (max-width: 425px) {
        min-width: 90px;
    }

    @include transition-all();

    &:hover {
        color: $color-blue;
        border-color: $color-blue;
    }

    .filter__input:checked + & {
        color: #fff;
        border-color: $color-blue;
        background-color: $color-blue;
    }
}

.filter__input,
.submit__btn,
.reset__btn {
    display: none;
}