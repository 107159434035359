
.banner {
    position: relative;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
}

.banner__content {
    padding: 0;

    @media screen and (min-width: 601px) {
        display: flex;
        align-items: center;
        height: 460px;

        @media screen and (max-width: 1024px) {
            height: 400px;
        }
        @media screen and (max-width: 900px) {
            height: 350px;
        }
        @media screen and (max-width: 768px) {
            height: 325px;
        }
        @media screen and (max-width: 680px) {
            height: 300px;
        }
    }
}

.banner__bg-wrapper {

    @media screen and (max-width: 600px) {
        position: relative;
        height: 240px;
    }
}

.banner__bg {
    @include imgCover();
}

.banner__text-wrapper {
    position: relative;

    @media screen and (max-width: 600px) {
        padding: 40px 35px 45px;
    }
    @media screen and (max-width: 425px) {
        padding: 35px 25px 40px;
    }
    @media screen and (max-width: 375px) {
        padding: 35px 20px 40px;
    }
}

.banner__heading {
    @include font-size-20();
}

.banner__name {
    margin-top: 25px;
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();

    @media screen and (max-width: 900px) {
        margin-top: 20px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 15px;
    }
}

.banner__position {
    margin-top: 5px;
    @include font-size-20();
}

.banner__contact {
    margin-top: 30px;
    @include font-size-20();

    @media screen and (max-width: 900px) {
        margin-top: 25px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 20px;
    }
}

.banner__contact-link {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-color: #000;
    text-decoration-color: #000;

    &:hover {
        -webkit-text-decoration-color: transparent;
        text-decoration-color: transparent;
    }
}