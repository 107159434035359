
.milestone-wrapper {
    margin-top: 50px;
}

.milestone-list {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        height: 100%;
        width: 0;
        border-left: 2px dotted #000;

        @media screen and (max-width: 479px) {
            left: 0;
        }
    }
}

.milestone {
    position: relative;
    width: calc(50% - 30px);
    min-height: 185px;
    padding: 20px;
    background-color: #fff;

    @include transition-all(.45s);
    @include transform(translateX(20px));
    opacity: 0;

    &.animate {
        @include transform(translateX(0));
        opacity: 1;

        &:after {
            @include transform(translateX(0));
        }
    }

    &:after,
    &:before {
        content: '';
        position: absolute;
        display: block;
    }
    &:after {
        @include transition-all(.45s);
        @include transform(translateX(-20px));
        opacity: 1;
    }

    @media screen and (max-width: 600px) {
        min-height: 145px;
    }
    @media screen and (max-width: 479px) {
        width: calc(100% - 30px);
        min-height: 0;
        margin-top: 20px;
        margin-left: 30px;
        @include transform(translateX(-20px));

        &:after {
            top: 27px;
            left: -34px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #000;
            @include transform(translateX(20px));
        }
        &:before {
            top: 15px;
            left: -20px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16.5px 20px 16.5px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    @media screen and (min-width: 480px) {
        &:after {
            top: 27px;
            right: -36px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #000;
        }

        &:before {
            top: 15px;
            right: -20px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 16.5px 0 16.5px 20px;
            border-color: transparent transparent transparent #ffffff;
        }

        &:nth-child(even) {
            margin-top: (-185px/2);
            margin-left: calc(50% + 30px);

            @include transform(translateX(-20px));

            &.animate {
                @include transform(translateX(0));

                &:after {
                    @include transform(translateX(0));
                }
            }

            @media screen and (max-width: 600px) {
                margin-top: -110px;
            }

            &:after {
                left: -34px;
                @include transform(translateX(20px));
            }

            &:before {
                left: -20px;
                border-style: solid;
                border-width: 16.5px 20px 16.5px 0;
                border-color: transparent #ffffff transparent transparent;
            }
        }
    }
}

.milestone__year {
    line-height: 20px;
    padding: 3px 13px;
    font-size: 10px;
    color: $color-blue;
    letter-spacing: .4px;
    border: 1px solid $color-blue;
    border-radius: 5px;
}

.milestone__heading {
    margin-top: 10px;
    line-height: (28/20);
    font-weight: 700;
    @include font-size-20();
}