
.contact-form-overlay {
    z-index: 555;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $color-blue center no-repeat;
    background-size: cover;
    pointer-events: none;
    @include transform(translateX(100%));
    @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .75s);

    @media screen and (min-width: 501px) {
        .no-webp & {
            background-image: url("../web_images/bg_contact-form.jpg");
        }
        .webp & {
            background-image: url("../web_images/bg_contact-form.webp");
        }
    }
    @media screen and (max-width: 500px) {
        .no-webp & {
            background-image: url("../web_images/bg_contact-form_mobile.jpg");
        }
        .webp & {
            background-image: url("../web_images/bg_contact-form_mobile.webp");
        }
    }

    &--active {
        pointer-events: all;
        @include transform(translateX(0));
    }
}

.contact-form-close {
    z-index: 1;
    position: fixed;
    top: 45px;
    right: 45px;
    display: block;
    width: 30px;
    height: 30px;

    @media screen and (max-width: 700px) {
        top: 30px;
        right: 30px;
    }

    &--mobile {
        @media screen and (min-width: 601px) {
            display: none;
        }
        @media screen and (max-width: 600px) {
            position: absolute;
            top: -5px;
            right: 0;
        }
    }
    &--desktop {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &:hover {
        @include transform(scale(1.2));
    }

    &:before,
    &:after {
        position: absolute;
        content: ' ';
        top: 6px;
        left: 15px;
        height: 20px;
        width: 2px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}

.contact-form-overlay__inner {
    width: 100%;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    overflow-y: auto;

    padding: 0 45px;

    @media screen and (max-width: 768px) {
        padding: 0 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 0 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 20px;
    }
}

.contact-form {
    margin: 50px auto;
    max-width: 535px;
    width: 100%;
    opacity: 0;

    @media screen and (min-width: 601px) {
        @include transform(translateX(50px));
        @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .5s .3s);
    }
    @media screen and (max-width: 600px) {
        @include transform(translateX(15px));
        @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .75s .15s);
    }

    .contact-form-overlay--active & {
        @include transform(translateX(0));
        opacity: 1;
    }

    @media screen and (max-width: 600px) {
        margin: 35px auto;
    }
    @media screen and (max-width: 425px) {
        margin: 30px auto;
    }
}

.contact-form__heading {
    position: relative;
    margin-bottom: 40px;
    line-height: 1.4;
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;

    @media screen and (min-width: 601px) {
        text-align: center;
    }
    @media screen and (max-width: 600px) {
        margin-bottom: 35px;
        padding-right: 50px;
    }
    @media screen and (max-width: 425px) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 360px) {
        font-size: 16px;
    }
}


/* vstupy formu */

.contact-form__input-wrapper {
    position: relative;
    font-size: 0;
    overflow: hidden;
    display: flex;
    width: 100%;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }

    & + & {
        margin-top: 18px;

        @media screen and (max-width: 600px) {
            margin-top: 12px;
        }
    }
}

.contact-form__input-wrapper__spacer {
    flex-shrink: 0;
    width: 16px;

    @media screen and (max-width: 600px) {
        height: 12px;
    }
}

.contact-form__input,
.contact-form__textarea {
    @include input();
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;

    &:hover {
        background-color: $color-blue;
    }
    &:focus {
        background-color: $color-blue;
    }

    @include placeholder() {
        color: #fff;
    };
}
.contact-form__textarea {
    height: auto;
    padding: 13px 22px;
    line-height: 1.5;
    resize: none;

    @media screen and (max-width: 425px) {
        padding: 11px 18px;
    }
}
.contact-form__input--select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.contact-form__files-input {
    display: none;
}


/* validace */

.not-validated + .contact-form__gdpr-text {
    color: #ff434b;
}

.contact-form .not-validated {
    border-color: #ff434b;

    &:focus {
        border-color: #ff434b;
    }
    &:hover {
        border-color: #ff434b;
    }
}


/* gdpr & req hint */

.contact-form__bottom {
    margin-top: 25px;
    color: #fff;

    &--one-btn {
        @media screen and (min-width: 510px) {
            display: flex;
            justify-content: space-between;
        }
    }
    &--two-btns {
    }
}

.contact-form__gdpr-label {
    display: flex;
    align-items: center;
    font-size: 12px;

    & + & {
        margin-top: 10px;
    }
}

.contact-form__gdpr-checkbox {
    margin-right: 5px;
}

.contact-form__gdpr-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}


/* zapati formu */

.contact-form__submit-outer-wrapper {
    display: flex;
}

.contact-form__submit-wrapper {

    &--one-btn {
        position: relative;

        @media screen and (min-width: 510px) {
            display: flex;
        }
    }
    &--two-btns {
        margin-top: 30px;
        display: flex;

        @media screen and (max-width: 509px) {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (max-width: 509px) {
        margin-top: 20px;
    }
}

.contact-form__submit-shadow {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 49px;
    padding: 0 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: #000;
    background-color: #fff;
    border: 1px solid #fff;
    @include transition-all();

    .contact-form__submit-wrapper--one-btn:hover & {
        color: #fff;
        background-color: $color-blue;
    }
}

.contact-form__cta {
    line-height: 47px;
    padding: 0 26px;
    text-align: center;
    border: 1px solid #fff;

    @media screen and (min-width: 510px) {
        margin-left: auto;
    }
    @media screen and (max-width: 509px) {
        margin-top: 10px;
    }

    &:hover {
        color: #fff;
        background-color: $color-blue;
    }
}


/* recaptcha */

.contact-form__recaptcha {
    margin-top: 40px;
    font-size: 12px;
    opacity: .75;
    color: #fff;
}