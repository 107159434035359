
.docs-wrapper {
    margin-top: 40px;
}

.docs-list {
    display: flex;
    flex-wrap: wrap;
    margin: -9px;
}

.docs {
    position: relative;
    margin: 9px;
    width: calc(25% - 18px);

    @media screen and (max-width: 900px) {
        width: calc(33.33% - 18px);
    }
    @media screen and (max-width: 680px) {
        width: calc(50% - 18px);
    }
    @media screen and (max-width: 395px) {
        width: 100%;
    }
}

.docs__checkbox-input {
    display: none;
}

.docs__checkbox {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 2px solid #000;
    @include transition-all();

    &:after {
        @include before-after(2px, 0, 8px, 15px);
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        @include transition(all ease-in-out .15s);
        @include transform(scale(0) rotate(45deg));
        @include transform-origin(bottom right);
    }
    .docs__checkbox-input:checked + .docs__link &:after {
        @include transform(scale(1) rotate(45deg));
    }
}

.docs__link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 30px 30px 35px;
    background-color: #fff;
    @include transition-all();

    @media screen and (max-width: 1100px) {
        padding: 25px 25px 30px;
    }
    @media screen and (max-width: 480px) {
        padding: 20px 20px 25px;
    }
    @media screen and (max-width: 395px) {
        padding: 25px 25px 30px;
    }

    &:hover {
        @include box-shadow(0px 0px 15px #0000001A);
    }
}

.docs__heading {
    margin-bottom: 30px;

    @media screen and (max-width: 1100px) {
        margin-bottom: 25px;
    }
    @media screen and (max-width: 480px) {
        margin-bottom: 20px;
    }

    @include font-size-20();

    .docs__link:hover & {
        font-weight: 700;
    }
}

.docs__ico {
    margin-top: auto;
    width: 92px;
    height: 120px;
    background: url("../web_images/ico_download-pdf.svg") center no-repeat;
    background-size: contain;
    opacity: .3;

    @include transition-custom(opacity);

    @media screen and (max-width: 1100px) {
        height: 100px;
    }
    @media screen and (max-width: 425px) {
        height: 90px;
    }

    .docs__link:hover &,
    .docs__checkbox-input:checked + .docs__link & {
        opacity: 1;
    }
}

.docs__cta {
    margin-top: 45px;
    font-weight: 700;
    text-decoration: underline solid 2px;
    opacity: .3;

    @include transition-custom(opacity);

    @media screen and (max-width: 1100px) {
        margin-top: 25px;
    }
    @media screen and (max-width: 480px) {
        margin-top: 20px;
    }

    .docs__link:hover & {
        opacity: 1;
    }
}