
.schema-placeholder {
    margin: 40px auto 80px;

    &:empty {
        padding-top: (295.719/1155)*100%;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 70px;
    }
    @media screen and (max-width: 425px) {
        margin-bottom: 60px;
    }
}

.schema {
    overflow: visible;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    -webkit-filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, .1));
}

.schema__wrapper {
    opacity: 0;

    &.animate {
        opacity: 1;
        @include transition(all 1s cubic-bezier(0.05, 0.06, 0.05, 0.95));
    }
}

.bounce-in {
    opacity: 0;

    &.animate {
        opacity: 1;
        @include transition(all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55));
    }
}

.schema__arrow {
    opacity: 0;

    &.animate {
        opacity: 1;
        @include transition((
            transform 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            -webkit-transform 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            opacity 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        ));
    }
}