
.features {

    @media screen and (min-width: 900px) {
        display: flex;
        flex-wrap: wrap;
        margin: 40px -8px 0;
    }
    @media screen and (max-width: 899px) {
        margin: 40px auto 0;
    }
}

.features__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 25px 20px 85px;
    background-color: #fff;

    @media screen and (min-width: 900px) {
        margin: 0 8px;
        width: calc(50% - 16px);
    }
    @media screen and (max-width: 600px) {
        padding-left: 55px;
    }
    @media screen and (max-width: 425px) {
        padding-left: 50px;
    }

    &:before {
        @include before-after(50%, 40px, 6px, 12px);
        background: url("../web_images/ico_li.svg") center no-repeat;
        background-size: contain;
        @include transform(translateY(-50%));

        @media screen and (max-width: 600px) {
            left: 27px;
        }
        @media screen and (max-width: 425px) {
            left: 24px;
        }
    }

    &:after {
        @include before-after(0, 12px, auto, 1px);
        right: 12px;
        background-color: #F2F2F2;
    }
}

.features__text-wrapper {
    line-height: (20/15);
    max-width: 375px;

    @media screen and (max-width: 899px) {
        max-width: 600px;
    }
}