
.header {
    z-index: 555;
    position: fixed;
    top: 0;
    left: 0;
    height: $header-height;
    width: 100%;
    background-color: #fff;
    @include box-shadow(0px 3px 6px #00000029);

    .modal-open & {
        z-index: 1;
    }
}

.header__container {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: $max-layout-width;
    margin: 0 auto;
}

.header__logo {
    height: 100%;
    width: 102px;
    background: url("../web_images/logo.svg") center left no-repeat;
    background-size: contain;
}

.menu__link {
    display: inline-block;
}

.menu__link-inner {
    position: relative;
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;
    @include transition-custom(text-decoration-color);

    .menu__link:hover & {
        -webkit-text-decoration-color: #000;
        text-decoration-color: #000;
    }
    &.active {
        text-decoration-thickness: 2px;
        -webkit-text-decoration-color: #000;
        text-decoration-color: #000;
        font-weight: 700;

        .menu__link:hover & {
            -webkit-text-decoration-color: transparent;
            text-decoration-color: transparent;
        }
    }
}

@media screen and (min-width: 951px) {

    .menu {
        display: flex;
        margin-left: auto;
    }

    .menu__item {
        position: relative;
        line-height: $header-height;
        font-size: 15px;
        white-space: nowrap;

        & + & {
            margin-left: 50px;

            @media screen and (max-width: 1100px) {
                margin-left: 35px;
            }
        }

        &--has-submenu {
            padding-right: 15px;

            &:after {
                pointer-events: none;
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 6px;
                height: 100%;
                background: url("../web_images/ico_menu-arrow.svg") center no-repeat;
                background-size: contain;
                transition: all .3s cubic-bezier(.29,.57,.31,.97);
                transform: rotate(90deg);
            }
            &:hover:after {
                transform: rotate(270deg);
            }
        }
    }


    .submenu {
        position: absolute;
        left: -20px;
        padding: 3px 20px;
        background-color: #fff;
        box-shadow: 0 3px 6px #00000029;

        transition: all .3s cubic-bezier(.29,.57,.31,.97);
        transform: translateY(-5px);
        opacity: 0;
        pointer-events: none;

        .menu__item--has-submenu:hover & {
            transform: translateX(0);
            opacity: 1;
            pointer-events: auto;

            &:before,
            &:after {
                opacity: 1;
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            pointer-events: none;
            opacity: 0;
            transition: all .3s cubic-bezier(.29,.57,.31,.97);
        }
        &:before {
            top: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            box-shadow: 0 3px 6px #00000029;
        }
        &:after {
            top: -10px;
            left: -10px;
            right: -10px;
            height: 10px;
            background-color: #fff;
        }
    }

    .submenu__link {
        position: relative;
        display: block;
        line-height: 45px;
        padding-right: 35px;

        &:hover {
            font-weight: 700;
        }
        &:hover {
            font-weight: 700;
        }

        & + & {
            border-top: 1px solid rgba(#000, 0.15);
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            width: 6px;
            height: 100%;
            background: url("../web_images/ico_menu-arrow.svg") center no-repeat;
            background-size: contain;
            transition: all .3s cubic-bezier(.29,.57,.31,.97);
            transform: translateX(-5px);
            opacity: 0;
        }
        &:hover:after {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .submenu__link-inner-shadow {
        font-weight: 700;
        opacity: 0;
    }

    .submenu__link-inner {
        position: absolute;
        inset: 0;
    }


    /* Hamburger menu */

    .hamburger-menu {
        display: none;
    }
}
@media screen and (max-width: 950px) {

    .menu {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        top: $header-height;
        background-color: #fff;
        padding: 10px 45px 40px;

        @media screen and (max-width: 768px) {
            & {
                padding-left: 35px;
                padding-right: 35px;
            }
        }
        @media screen and (max-width: 425px) {
            & {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
        @media screen and (max-width: 375px) {
            & {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        list-style: none;

        pointer-events: none;
        opacity: 0;
        height: 0;
        overflow: hidden;

        @include transition((opacity .3s ease-out 0s, height 0s ease-out .3s));
    }
    .menu.active {
        pointer-events: auto;
        opacity: 1;
        height: calc(100% - #{$header-height});
        overflow-y: auto;

        @include transition((opacity .3s ease-out 0s, height 0s ease-out 0s));
    }

    .menu__item {
        position: relative;
        padding: 10px 0;
        opacity: 0;
        line-height: 40px;
        font-size: 22px;

        @media screen and (max-width: 600px) {
            font-size: 20px;
        }

        @include transform(translateX(50px));
        @include transition-all();

        .menu.active & {
            opacity: 1;
            @include transform(translateX(0));
        }

        /*&--has-submenu {
            padding-right: 15px;

            &:after {
                pointer-events: none;
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 6px;
                height: 60px;
                background: url("../web_images/ico_menu-arrow.svg") center no-repeat;
                background-size: contain;
                transition: all .3s cubic-bezier(.29,.57,.31,.97);
                transform: rotate(90deg);
            }
            &:hover:after {
                transform: rotate(270deg);
            }
        }*/
        &--has-submenu {

            &:after {
                pointer-events: none;
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 6px;
                height: 60px;
                background: url("../web_images/ico_menu-arrow.svg") center no-repeat;
                background-size: contain;
                transform: rotate(-270deg);
            }
        }
    }


    .submenu {
        margin: 5px -45px 0;
        padding: 3px 45px;
        background-color: #f2f2f2;

        @media screen and (max-width: 768px) {
            margin: 5px -35px 0;
            padding: 3px 35px;
        }
        @media screen and (max-width: 425px) {
            margin: 5px -25px 0;
            padding: 3px 25px;
        }
        @media screen and (max-width: 375px) {
            margin: 5px -20px 0;
            padding: 3px 20px;
        }

        transition: all .3s cubic-bezier(.29,.57,.31,.97);
        //opacity: 0;
        //pointer-events: none;

        .menu__item--has-submenu:hover & {
            //opacity: 1;
            //pointer-events: auto;
        }
    }

    .submenu__link {
        position: relative;
        display: block;
        line-height: 45px;
        padding-right: 35px;
        font-size: 18px;

        @media screen and (max-width: 600px) {
            font-size: 16px;
        }

        &:hover {
            font-weight: 700;
        }
        &:hover {
            font-weight: 700;
        }

        & + & {
            border-top: 1px solid rgba(#000, 0.15);
        }

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            width: 6px;
            height: 100%;
            background: url("../web_images/ico_menu-arrow.svg") center no-repeat;
            background-size: contain;
            transition: all .3s cubic-bezier(.29,.57,.31,.97);
            transform: translateX(-5px);
            opacity: 0;
        }
        &:hover:after {
            transform: translateX(0);
            opacity: 1;
        }
    }

    .submenu__link-inner-shadow {
        display: none;
    }

    .submenu__link-inner {
    }


    /* Hamburger menu */

    $hm-before-top: 8px;
    $hm-after-top: $hm-before-top*2;
    $hm-height: 3px;
    $hm-width: 30px;
    $hm-offset-top: -($hm-after-top + $hm-height)/2;
    $hm-offset-left: -($hm-width/2);

    .hamburger-menu {
        position: relative;
        right: -5px;
        z-index: 10000;
        width: 40px;
        height: 40px;
        margin-left: auto;
    }

    .hamburger-menu__breadroll {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: $hm-offset-top 0 0 $hm-offset-left;
    }

    .hamburger-menu__beef,
    .hamburger-menu__beef::before,
    .hamburger-menu__beef::after {
        display: inline-block;
        position: absolute;
        width: $hm-width;
        height: $hm-height;

        background-color: #000;

        @include transition-all(.25s);
    }
    .hamburger-menu__beef::before {
        top: $hm-before-top;
        content: "";
        opacity: 1;
    }
    .hamburger-menu__beef::after {
        top: $hm-after-top;
        content: "";
    }
    .hamburger-menu.active .hamburger-menu__beef {
        @include transform(translate3d(0px, $hm-before-top, 0px) rotate(45deg));
    }
    .hamburger-menu.active .hamburger-menu__beef::before {
        opacity: 0;
    }
    .hamburger-menu.active .hamburger-menu__beef::after {
        @include transform(translate3d(0px, -$hm-after-top, 0px) rotate(-90deg));
    }
}