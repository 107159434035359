
.intro-para {

}

.intro-para__content {
    padding: 100px 0 150px;

    @media screen and (max-width: 1280px) {
        padding-bottom: 130px;
    }
    @media screen and (max-width: 1200px) {
        padding: 90px 0 120px;
    }
    @media screen and (max-width: 1024px) {
        padding: 80px 0 110px;
    }
    &--subpage {
        @media screen and (min-width: 769px) {
            padding-top: 170px;

            @media screen and (max-width: 1200px) {
                padding-top: 160px;
            }
            @media screen and (max-width: 1024px) {
                padding-top: 150px;
            }
            @media screen and (max-width: 900px) {
                padding-top: 140px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 70px 0 90px;
    }
    @media screen and (max-width: 425px) {
        padding: 60px 0 75px;
    }
}

.intro-para__para {
    line-height: (40/25);
    font-weight: 700;
    font-size: 25px;

    @media screen and (max-width: 1024px) {
        font-size: 23px;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
    @media screen and (max-width: 600px) {
        font-size: 19px;
    }
    @media screen and (max-width: 425px) {
        font-size: 18px;
    }
    @media screen and (max-width: 350px) {
        font-size: 17px;
    }

    @media screen and (max-width: 680px) {
        br {
            display: none;
        }
    }

    & + & {
        margin-top: 30px;

        @media screen and (max-width: 600px) {
            margin-top: 20px;
        }
    }
}