
.nl__form {
    margin-top: 55px;

    @media screen and (max-width: 768px) {
        margin-top: 45px;
    }
    @media screen and (max-width: 480px) {
        margin-top: 35px;
    }
}

.nl__input-wrapper {
    display: flex;
}

.nl__input {
    @include input();
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    background-color: #000;
    border: 1px solid #fff;

    &:hover,
    &:focus {
        background-color: $color-blue;
    }

    @include placeholder() {
        color: #fff;
    };
}

.nl__submit {
    display: block;
    text-align: center;
    padding: 0 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    border: 1px solid #fff;

    @media screen and (max-width: 425px) {
        padding: 0 30px;
    }

    &:hover {
        color: #fff;
        background-color: $color-blue;
    }
}

.nl__gdpr-label {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 425px) {
        margin-top: 20px;
    }
}

.nl__gdpr-checkbox {
    margin-right: 5px;
}

.nl__gdpr-text {
    font-size: 12px;
}

.nl__gdpr-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.nl__hidden {
    display: none;
}