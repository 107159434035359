
#freeprivacypolicy-com---nb {
    right: 0;
    bottom: 0;
    max-width: 700px;
    height: auto !important;
    color: $color-blue;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, .07);
    opacity: 0;

    @media screen and (max-width: 768px) {
        left: 20px;
        right: 20px;
        bottom: 20px;
        max-width: 100%;
    }

    @include animation-full(consent-fade-in .6s 1.5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards);

    @include keyframes(consent-fade-in) {
        from {
            opacity: 0;
            @include transform(scale(.975));
        }
        to {
            opacity: 1;
            @include transform(scale(1));
        }
    }


    .cc-nb-main-container {
        padding: 2rem 2.25rem;

        @media screen and (max-width: 600px) {
            padding: 1.75rem 2rem;
        }
        @media screen and (max-width: 480px) {
            padding: 1.5rem 1.75rem;
        }
    }

    .cc-nb-title {
        line-height: 1.2;
        font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            font-size: 19px;
        }
        @media screen and (max-width: 600px) {
            font-size: 18px;
        }
        @media screen and (max-width: 425px) {
            font-size: 17px;
        }
        @media screen and (max-width: 395px) {
            font-size: 16px;
        }
    }

    .cc-nb-text {
        max-height: 170px;
        overflow: auto;
        font-size: 15px;
    }

    .cc-nb-okagree,
    .cc-nb-reject,
    .cc-nb-changep {
        margin-right: .5rem !important;
        height: 45px;
        padding: 0 20px;
        font-size: 18px;
        font-weight: 700;

        @media screen and (max-width: 340px) {
            font-size: 16px;
        }
    }
    .cc-nb-okagree,
    .cc-nb-reject {
        color: #fff;
        background-color: $color-blue;

        &:hover {
            background-color: #000;
        }
    }
    .cc-nb-changep {
        padding: 0 10px;
        color: $color-blue;
        background-color: transparent;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        -webkit-text-decoration-color: currentColor;
        text-decoration-color: currentColor;

        &:hover {
            color: #000;
        }
    }
}


.cookie-fpp-button {
    z-index: 555;
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        border-color: $color-blue;

        &:after {
            opacity: 1;
        }
    }
    &:after {
        content: '';
        background: #fff url('../web_images/ico_cookies.svg') center no-repeat;
        background-size: contain;
        opacity: 0.6;
        position: relative;
        display: block;
        width: 60%;
        height: 60%;
    }

    @media screen and (max-width: 768px) {
        width: 55px;
        height: 55px;
    }
    @media screen and (max-width: 375px) {
        width: 50px;
        height: 50px;
    }
}