
.cards-wrapper {
    margin-top: 40px;
}

.cards-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -8px;

    @media screen and (max-width: 600px) {
        margin: -8px auto;
        max-width: 330px;
    }
}

.cards {
    position: relative;
    margin: 8px;
    width: calc(33.33% - 16px);

    @media screen and (max-width: 768px) {
        width: calc(50% - 16px);
    }
    @media screen and (max-width: 600px) {
        margin: 8px 0;
        width: 100%;
    }
}

.cards__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    padding-bottom: 45px;
    background-color: #fff;

    @media screen and (max-width: 600px) {
        padding-bottom: 35px;
    }

    &:hover {
        @include box-shadow(0px 0px 15px #0000001A);
    }
}

.cards__img-wrapper {
    padding: 35px 30px 50px;

    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 600px) {
        padding-bottom: 20px;
    }
}

.cards__img {
    max-width: 100%;
    height: auto;
    @include transition(all .3s cubic-bezier(.37,.7,.31,.98));
    opacity: 0;

    &.loaded.animate {
        opacity: 1;
    }
}

.cards__text-wrapper {

}

.cards__heading {
    padding: 0 15px;
    line-height: (22/20);
    font-weight: 700;
    @include font-size-20();
}

.cards__cta-wrapper {
    margin-top: auto;
}

.cards__cta {
    color: #0000004D;
}