
.post__breadcrumbs {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post__breadcrumbs-link {
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;

    &:hover {
        color: $color-blue;
        -webkit-text-decoration-color: currentColor;
        text-decoration-color: currentColor;
    }

    &--cur {
        font-weight: 700;
    }
}

.post__heading-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.post__tag {
    margin-top: 30px;
    min-width: 93px;
    padding: 0 10px;
    line-height: 19px;
    font-size: 10px;
    color: $color-blue;
    letter-spacing: .4px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid $color-blue;
}

.post__heading {
    margin-top: 10px;
    max-width: 630px;
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();

    &--stories {
        margin-top: 60px;
        margin-bottom: 150px;

        @media screen and (max-width: 900px) {
            margin-top: 45px;
            margin-bottom: 130px;
        }
        @media screen and (max-width: 600px) {
            margin-top: 35px;
            margin-bottom: 115px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 30px;
            margin-bottom: 105px;
        }
    }
}

.post__date {
    margin-top: 45px;
    margin-bottom: 90px;
    font-size: 12px;

    @media screen and (max-width: 768px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 30px;
    }
}

.post__img-wrapper {
    position: relative;
    margin-top: -200px;
    margin-bottom: 70px;
    padding-bottom: 65.67%;
    background-color: $color-blue;

    @media screen and (max-width: 768px) {
        margin-bottom: 50px;
        margin-top: -190px;
    }
    @media screen and (max-width: 425px) {
        margin-bottom: 35px;
        margin-top: -180px;
    }
}

.post__img {
    @include imgCover();
    @include transition(all .3s cubic-bezier(.37,.7,.31,.98));
    opacity: 0;

    &.loaded.animate {
        opacity: 1;
    }
}

.post__img-filter {
    @include imgCover();
    background-color: rgba(0, 0, 0, .3);
}

.post__text-wrapper {
    line-height: (22/15);

    h2 {
        margin-top: 70px;
        line-height: (30/20);
        font-size: 22px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
            margin-top: 50px;
            font-size: 20px;
        }
        @media screen and (max-width: 600px) {
            font-size: 18px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 35px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        margin-top: 40px;
        font-size: 19px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
            font-size: 18px;
        }
        @media screen and (max-width: 600px) {
            font-size: 17px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 20px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        margin-top: 40px;
        font-size: 17px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
            font-size: 16px;
        }
        @media screen and (max-width: 600px) {
            font-size: 15px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 20px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    h3 + h2,
    h4 + h2,
    h2 + h3,
    h4 + h3,
    h2 + h4,
    h3 + h4 {
        margin-top: 25px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 17px;
        }
    }

    p {
        margin-top: 25px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 17px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    q {
        position: relative;
        margin-top: 70px;
        display: block;
        text-align: center;
        padding: 35px 0 40px;
        line-height: (40/25);
        font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
        font-size: 25px;
        color: $color-blue;

        &.quote--longer {
            text-align: left;
            line-height: 1.7;
            font-size: 18px;

            @media screen and (max-width: 425px) {
                font-size: 15px;
            }
        }

        @media screen and (max-width: 768px) {
            margin-top: 50px;
            padding: 30px 0 35px;
            font-size: 23px;
        }
        @media screen and (max-width: 680px) {
            padding: 30px 20px 35px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 35px;
            padding: 20px 15px 25px;
            font-size: 20px;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -145px;
            right: -145px;
            display: block;
            height: 2px;
            background-color: $color-blue;

            @media screen and (max-width: 950px) {
                left: -75px;
                right: -75px;
            }
            @media screen and (max-width: 800px) {
                left: -40px;
                right: -40px;
            }
            @media screen and (max-width: 680px) {
                left: 0;
                right: 0;
            }
        }
        &:after {
            top: auto;
            bottom: 0;
        }
    }

    ul,
    ol {
        margin-top: 25px;

        @media screen and (max-width: 768px) {
            margin-top: 10px;
        }

        &:first-child {
            margin-top: 0;
        }

        p {
            margin-top: 10px;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 20px;
    }

    ul {
        @media screen and (max-width: 680px) {
            padding-left: 15px;
        }
    }

    ol li {

        & + li {
            margin-top: 10px;
        }
    }

    ul li {
        position: relative;

        &:before {
            @include before-after(5px, -15px, 6px, 12px);
            background: url("../web_images/ico_li.svg") center no-repeat;
            background-size: contain;
        }
    }

    table {
        margin-top: 10px;
        width: 100%;
    }

    tr {

    }

    th,
    td {
        padding: 5px 10px;
        border: 1px solid #000;
    }
    th {
        text-align: left;
        font-weight: 700;
    }
    td {

    }

    .image-wrapper {
        display: block;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    a {
        text-decoration: underline;
        -webkit-text-decoration-color: currentColor;
        text-decoration-color: currentColor;

        &:hover {
            color: $color-blue;
        }
    }

    .iframe-wrapper {
        position: relative;
        display: block;
        padding-top: 56.25%;
        border: 1px solid #f2f2f2;

        iframe {
            @include before-after();
        }
    }

    .button-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -5px;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            margin-top: 15px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 12px;
        }

        a {
            display: inline-flex;
            align-items: center;
            text-align: center;
            margin: 5px;
            padding: 10px 40px;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            background-color: $color-blue;
            text-decoration: none;

            &:hover {
                background-color: #000;
            }
        }
    }
}


.post__bottom {
    display: flex;
    border-top: 2px solid #000;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        gap: 45px;
    }
}

.post__author {
    display: flex;
    align-items: center;
    gap: 25px;

    @media screen and (max-width: 768px) {
        gap: 20px;
    }
    @media screen and (max-width: 680px) {
        gap: 17px;
    }
    @media screen and (max-width: 375px) {
        flex-direction: column;
        align-items: center;
    }
}

.post__author__photo-wrapper {
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 80px;
    background-color: rgba(#000, .1);
    border-radius: 50%;
}

.post__author__photo {
    position: absolute;
    inset: 0;
    background: center top no-repeat;
    background-size: 110%;
    @include transition(all .3s cubic-bezier(.37,.7,.31,.98));
    opacity: 0;

    &.loaded {
        opacity: 1;
    }

    &--marketing-manager {
        background-size: 133%;
    }
    &--bdm {
        background-size: 120%;
        background-position: left -5% top;
    }
}

.post__author__info {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 375px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.post__author__name {
    font-weight: 700;
}

.post__author__name-sep {
    padding: 0 5px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
        padding: 0 2px;
    }
    @media screen and (max-width: 680px) {
        display: block;
        height: 2px;
        font-size: 0;
    }
}

.post__author__position {
    font-style: italic;
    font-weight: 400;
}

.post__author__link {
    margin-top: 10px;
    display: inline-flex;

    &:hover {
        color: $color-blue;
    }

    @media screen and (max-width: 680px) {
        margin-top: 7px;
    }

    & + & {
        margin-top: 0;
    }
}

.post__author__link-ico {
    width: 20px;
    line-height: (22/15);
    background: center right no-repeat;
    background-size: contain;
    text-decoration: underline;

    &--email {
        background-image: url("../web_images/ico_author-email.svg");
        background-size: 22px;
    }
    &--in {
        background-image: url("../web_images/ico_author-in.svg");
        background-size: 17px;
    }
}

.post__author__link-text {
    margin-left: 10px;
    line-height: (22/15);
    text-decoration: underline;

    @media screen and (max-width: 375px) {
        margin-left: 5px;
    }
}


.post__social-media {
    display: flex;
    align-items: center;
    font-weight: 700;

    @media screen and (min-width: 601px) {
        margin-left: auto;
        margin-bottom: auto;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 15px;
    }

    &--desktop {
        @media screen and (min-width: 551px) {
            display: flex;

        }
        @media screen and (max-width: 550px) {
            display: none;
        }
    }
    &--mobile {
        @media screen and (min-width: 551px) {
            display: none;
        }
        @media screen and (max-width: 550px) {
            display: flex;
            margin-top: 50px;
        }
    }
}

.post__social-media__links {
    display: flex;

    @media screen and (min-width: 601px) {
        margin-left: 15px;
    }
}

.post__social-media__link {
    width: 31px;
    height: 31px;
    background: #000 center no-repeat;
    border-radius: 50%;
    @include transform(scale(1.001));

    &:hover {
        @include transform(scale(1.25));
    }

    &--twitter {
        background-image: url("../web_images/ico_soc-white-twitter.svg");
    }
    &--in {
        background-image: url("../web_images/ico_soc-white-in.svg");
        background-position-y: 7px;
    }

    & + & {
        margin-left: 15px;
    }
}