
.linkedin {
    margin: 0 -8px;
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
        padding: 0 50px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 45px;
    }
    @media screen and (max-width: 680px) {
        margin-right: auto;
        margin-left: auto;
        max-width: 506px;
    }
    @media screen and (max-width: 576px) {
        max-width: 416px;
        padding: 0 0 50px;
    }
    @media screen and (max-width: 470px) {
        margin-right: -8px;
        margin-left: -8px;
        max-width: none;
    }

    .slick-track {
        display: flex;
        margin: 0 auto;
    }

    .slick-slider {
        position: relative;
        width: 100%;
        margin: 0 auto;
        max-width: none;
    }

    .slick-list {
        width: 100%;
    }

    .slick-arrow {
        -webkit-filter: brightness(5) grayscale(1) contrast(5) invert(1);
        filter: brightness(5) grayscale(1) contrast(5) invert(1);

        &.slick-disabled {
            opacity: .3;
            pointer-events: none;
        }
    }

    @media screen and (max-width: 1200px) {
        .slick-prev {
            left: -50px;
        }
        .slick-next {
            right: -50px;
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
        }
    }
    @media screen and (max-width: 576px) {
        .slick-prev,
        .slick-next {
            bottom: -18px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        width: 100%;

        li {
            font-size: 0;

            & + li {
                margin-left: 8px;
            }
        }

        button {
            width: 5px;
            height: 5px;
            font-size: 0;
            background-color: #fff;
        }
        .slick-active button {
            background-color: $color-blue;
        }
    }
}

.linkedin__item {
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    background-color: #262626;
}

.linkedin__top {
    display: flex;
    padding: 20px 23px 0;
}

.linkedin__top-left {
    display: flex;
    align-items: center;
}

.linkedin__co-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url("../web_images/ico_in_magmio.svg") center no-repeat;
    background-size: contain;
}

.linkedin__info {
    margin-left: 10px;
}

.linkedin__co-name {
    font-size: 18px;
    font-weight: 700;
}

.linkedin__published-at {
    margin-top: 2px;
    font-size: 15px;
    color: #656565;
}

.linkedin__in-logo {
    margin-top: 5px;
    margin-left: auto;
    width: 25px;
    height: 25px;
    background: url("../web_images/ico_in_logo.svg") center no-repeat;
    background-size: contain;
}

.linkedin__text-wrapper {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    padding: 0 23px 20px;
}

.linkedin__para {
    line-height: (22/15);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;

    &--no-img {
        //-webkit-line-clamp: 10;
    }
}

.linkedin__more {
    margin-top: 20px;
    margin-left: auto;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-color: #fff;
    text-decoration-color: #fff;

    &:hover {
        -webkit-text-decoration-color: transparent;
        text-decoration-color: transparent;
    }
}

.linkedin__img-wrapper {
    display: block;
    padding-bottom: (240/425)*100%;
    background: $color-blue center no-repeat;
    background-size: cover;

    &--no-img {
        background-image: url("../web_images/bg_linkedin-post.webp");
    }
}

.linkedin__bot {
    margin-top: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 23px;
}

.linkedin__likes {
    padding-left: 25px;
    background: url("../web_images/ico_in_like.svg") center left no-repeat;
    background-size: contain;
}

.linkedin__share {
    padding-left: 25px;
    background: url("../web_images/ico_in_share.svg") center left no-repeat;
    background-size: contain;
}

.linkedin__share-panel {
    position: absolute;
    bottom: 30px;
    right: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    background-color: #262626;
    box-shadow: 0 3px 10px 0 rgba(#000, .5);
    opacity: 0;
    pointer-events: none;
    @include transform(translateY(5px));
    @include transition-all(.2s);

    &.open {
        opacity: 1;
        pointer-events: auto;
        @include transform(translateY(0));
    }
}

.linkedin__share-panel-link {
    padding: 6px 15px;

    &:hover {
        background-color: rgba(#fff, .2);
    }
}