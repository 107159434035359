

$offset: 25px;


.vacancy-wrapper {
    margin-top: 30px;
}

.vacancy-list {

}

.vacancy {
    z-index: 1;
    position: relative;
    margin-top: -1px;
    border-bottom: 1px solid #000;

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -$offset;
        right: -$offset;
        display: block;
        background-color: transparent;

        @include transition(all .4s ease);
    }

    &.active {
        margin-bottom: 30px;
        border-bottom-color: transparent;

        &:before {
            background-color: #f2f2f2;
        }
    }

    .admin-actions.small {
        width: auto;
    }
}

.vacancy__title {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.vacancy__title-text {
    @include font-size-20();

    .vacancy__title:hover &,
    .vacancy.active & {
        font-weight: 700;
    }
}

.vacancy__arrow {
    margin-left: auto;
    width: 12px;
    height: 6px;
    background: url("../web_images/ico_rolldown.svg") center no-repeat;
    background-size: contain;
    @include transition-all();

    .vacancy.active & {
        @include rotate(180deg);
    }
}

.vacancy__text-wrapper {
    margin: 0 (-$offset);
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    @include transition(all .4s ease);

    .vacancy.active & {
        opacity: 1;
    }
}

.vacancy__text {
    max-width: 800px;
    padding: 15px $offset 35px ($offset*2);
    line-height: (22/15);

    @media screen and (max-width: 900px) {
        padding: 10px 25px 35px 40px;
    }
    @media screen and (max-width: 768px) {
        padding: 10px 25px 35px;
    }
    @media screen and (max-width: 600px) {
        padding: 5px 25px 30px;
    }

    h4 {
        margin-top: 30px;
        margin-bottom: 15px;
        line-height: 1.25;
        font-weight: 700;

        @media screen and (max-width: 600px) {
            margin-top: 25px;
            margin-bottom: 10px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    p + p {
        margin-top: 15px;

        @media screen and (max-width: 768px) {
            margin-top: 10px;
        }
    }

    ul,
    ol {
        margin-top: 15px;

        @media screen and (max-width: 768px) {
            margin-top: 10px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 20px;
    }

    ul {
        @media screen and (max-width: 768px) {
            padding-left: 17px;
        }
    }

    /*li {

        & + li {
            margin-top: 7px;
        }
    }*/

    ul li {
        position: relative;

        &:before {
            @include before-after(5px, -15px, 6px, 12px);
            background: url("../web_images/ico_li.svg") center no-repeat;
            background-size: contain;
        }
    }

    a {
        text-decoration: underline;
        -webkit-text-decoration-color: currentColor;
        text-decoration-color: currentColor;

        &:hover {
            color: $color-blue;
        }
    }
}

.vacancy__contact {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 20px 0;
    line-height: (35/20);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #fff;
    background-color: #000;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (max-width: 600px) {
        height: 70px;
        font-size: 16px;
    }
    @media screen and (max-width: 540px) {
        flex-direction: column;
        height: auto;
        line-height: 2;
        padding: 15px 0;
    }
}

.vacancy__contact-link {
    padding: 0 20px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-color: #fff;
    text-decoration-color: #fff;

    &:hover {
        -webkit-text-decoration-color: transparent;
        text-decoration-color: transparent;
    }
}

.vacancy__contact-divider {

    @media screen and (max-width: 540px) {
        display: none;
    }
}