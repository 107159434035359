
.why-magmio {
    margin-top: 20px;
}

.why-magmio__item {
    border-bottom: 1px solid #000;
}

.why-magmio__title {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.why-magmio__title-text {
    @include font-size-20();

    .why-magmio__title:hover &,
    .why-magmio__title.active & {
        font-weight: 700;
    }
}

.why-magmio__arrow {
    margin-left: auto;
    width: 12px;
    height: 6px;
    background: url("../web_images/ico_rolldown.svg") center no-repeat;
    background-size: contain;
    @include transition-all();

    .why-magmio__title.active & {
        @include rotate(180deg);
    }
}

.why-magmio__text {
    max-width: 515px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    @include transition(all .4s ease);

    .why-magmio__title.active + & {
        opacity: 1;
    }
}

.why-magmio__para {
    line-height: (25/15);
    font-size: 15px;
}

.why-magmio__space {
    margin-bottom: 30px;
}