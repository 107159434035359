
.partners-wrapper {

}

.partners-content {
    overflow: hidden;
    padding-top: 80px;

    @media screen and (max-width: 768px) {
        padding: 70px 0 70px;
    }
    @media screen and (max-width: 425px) {
        padding: 60px 0 60px;
    }

    &--admin {
        max-width: 1155px;
    }
}

.partners-heading {
    max-width: 1155px;
    margin: 0 auto 40px;
    font-size: 20px;
    font-weight: 700;

    &--second {
        margin-top: 60px;
        padding-top: 25px;
        border-top: 1px solid #E5E5E5;
    }
}

.partners-container {

}

.partners-list {
    display: flex;

    &:not(&--admin) {
        opacity: 0;
        @include transition-custom(opacity, .6s);

        &.loaded {
            opacity: 1;
        }

        --time: 6s;

        &.partners-list--se {
            @include animation-full(slideshow-se var(--time) linear infinite);
        }
        &.partners-list--p {
            @include animation-full(slideshow-p var(--time) linear infinite);
        }
        &.partners-list--short {
            max-width: 1155px;
            margin: 0 auto;
            @include animation-full(none);
        }
    }

    &--admin {
        flex-wrap: wrap;
        gap: 25px;
    }
}

.partners {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    //height: 55px;
    margin-right: 90px;
    height: 50px;
    //width: 150px;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-right: 70px;
    }
    @media screen and (max-width: 425px) {
        margin-right: 45px;
    }

    .partners-list--admin & {
        justify-content: center;
        width: 150px;
        margin-right: 0;
    }
}

.partners__img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
}