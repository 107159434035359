
.news-wrapper {
    padding-top: 30px;

    @media screen and (max-width: 680px) {
        padding-top: 40px;
    }
}

.news-list {
    display: flex;
    flex-wrap: wrap;
    margin: -18px -8px -28px;

    @media screen and (max-width: 680px) {
        margin: -18px 0;
    }
    @media screen and (max-width: 425px) {
        margin: -14px 0;
    }
}

.news {
    margin: 28px 8px;
    width: calc(50% - 16px);
    position: relative;

    @media screen and (max-width: 680px) {
        width: 100%;
        max-width: 400px;
        margin: 18px auto;
    }
    @media screen and (max-width: 425px) {
        margin: 14px auto;
    }
}

.news__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;

    &:hover {
        @include box-shadow(0px 0px 15px #0000001A);
    }
}

.news__img-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    background-color: $color-blue;
}

.news__img {
    @include imgCover();
    /*background-blend-mode: multiply;
    background-color: rgba(0, 0, 0, .3);*/
    @include transition(all .3s cubic-bezier(.37,.7,.31,.98));
    opacity: 0;

    &.loaded.animate {
        opacity: 1;
    }
}

.news__filter {
    @include imgCover();
    background-color: rgba(0, 0, 0, .3);
}

.news__text-wrapper {
    padding: 30px 23px 0;

    @media screen and (max-width: 425px) {
        padding-top: 25px;
    }
}

.news__heading {
    line-height: (28/20);
    font-size: 20px;
    font-weight: 700;
}

.news__tag {
    display: inline-flex;
    justify-content: center;
    vertical-align: text-top;
    margin-right: 6px;
    min-width: 93px;
    padding: 0 10px;
    line-height: 19px;
    font-size: 10px;
    letter-spacing: .4px;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #0000004D;
    @include transition-all();

    .news__link:hover & {
        color: $color-blue;
        border-color: $color-blue;
    }
}

.news__date {
    margin-top: 13px;
    font-size: 12px;
}

.news__para {
    margin-top: 20px;
    max-width: 320px;
    line-height: (22/15);

    @media screen and (max-width: 425px) {
        margin-top: 15px;
    }
}

.news__footer {
    margin-top: auto;
    padding: 0 23px 30px;
}

.news__cta {
    color: #0000004D;
}