
.is-item-2,
.is-item-24 {
    margin: 50px 0 -20px;
    width: 100%;

    @media screen and (max-width: 900px) {
        max-width: 700px;
    }
}

.news__text-wrapper--latest {
    background-color: #f2f2f2;
}

@media screen and (min-width: 901px) {

    .news__link--latest {
        display: flex;
        flex-direction: row;

        &:hover {
            @include box-shadow(none);
        }
    }

    .news__img-wrapper--latest {
        flex-shrink: 0;
        width: 50%;
        padding-bottom: (68%/2);
    }

    .news__text-wrapper--latest {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 70px 90px;

        @media screen and (max-width: 1200px) {
            padding: 50px 60px;
        }
        @media screen and (max-width: 1024px) {
            padding: 40px 45px;
        }
    }

    .news__para--latest {
        max-height: 88px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 4;
    }
}

.news__tag--latest {
    color: $color-blue;
    border-color: $color-blue;
}

.news__footer--latest {
    margin-top: 0;
    padding: 0;

    @media screen and (max-width: 900px) {
        padding-bottom: 30px;
    }
}

.news__cta--latest {
    color: $color-blue;
}