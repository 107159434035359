
.team {
    display: flex;
    margin-top: 50px;
}

.team__img-wrapper {

    @media screen and (min-width: 900px) {
        &--desktop {
            flex-shrink: 0;
            padding-right: 75px;
            width: 50%;
            height: 470px;
        }
        &--mobile {
            display: none;
        }
    }
    @media screen and (max-width: 899px) {
        &--desktop {
            display: none;
        }
        &--mobile {
            overflow: hidden;
            position: relative;
            padding-top: 90%;

            &.no-image {
                background-color: #f2f2f2;
            }
        }
    }
}

@media screen and (min-width: 900px) {

    .team__inner-img-wrapper {
        position: relative;
        height: 100%;
        //background-color: $color-blue;
        clip-path: inset(0 100% 0 0);
        //opacity: 0;
        @include transition(all .6s ease);

        &.active {
            clip-path: inset(0);
            //opacity: 1;
        }
    }
}

.team__img {
    @include imgCover();

    @media screen and (min-width: 900px) {
        &--desktop {
            opacity: 0;
            @include transition(opacity .6s ease);
            @include transition-delay(0s);

            &.active {
                opacity: 1;
                @include transition-delay(.6s);
            }
        }
        &--mobile {
            display: none;
        }
    }
    @media screen and (max-width: 899px) {
        &--desktop {
            display: none;
        }
        &--mobile {
            height: auto;
        }
    }

    &.no-image {
        opacity: 0;
    }
}

@media screen and (min-width: 900px) {

    .team__text-wrapper {
        width: 50%;
    }

    .team__item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 5px 0;

        & + & {
            margin-top: 20px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: #000;
            @include transition-all(.5s);
            @include transform(scaleX(0));
            @include transform-origin(center left);
        }

        &.active,
        &:hover {
            font-weight: 700;

            &:after {
                @include transform(scaleX(1));
            }
        }
    }
}
@media screen and (max-width: 899px) {

    .team__text-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -15px -13px;

        @media screen and (max-width: 425px) {
            margin: -17px 0;
        }
    }

    .team__item {
        margin: 15px 13px;
        width: calc(33.33% - 26px);

        @media screen and (max-width: 767px) {
            width: calc(50% - 26px);
        }
        @media screen and (max-width: 425px) {
            margin: 17px 0;
            width: 100%;
        }
    }
}

.team__name {
    //font-size: 20px;
    @include font-size-20();

    @media screen and (max-width: 899px) {
        margin-top: 15px;
    }
}

.team__position {

    @media screen and (min-width: 900px) {
        min-width: 75px;
        margin-left: auto;
    }
    @media screen and (max-width: 899px) {
        margin-top: 5px;
    }
}