
.hero-subpage {
    position: relative;
    background-color: #f2f2f2;

    &--about-us {
        background-color: #f4f4f2;
    }

    @media screen and (max-width: 768px) {
        overflow: hidden;
        padding: 0;
    }
}

.hero-subpage__content {
    padding: 0;

    @media screen and (min-width: 769px) {
        height: 420px;

        @media screen and (max-width: 1280px) {
            height: 390px;
        }
    }
}

.hero-subpage__bg-wrapper {
    display: block;

    @media screen and (max-width: 768px) {
        position: relative;
        height: 250px;
    }
    @media screen and (max-width: 425px) {
        height: 210px;
    }
}

.hero-subpage__bg {
    display: block;

    @media screen and (min-width: 769px) {
        @include imgCover();

        &--about-us {
            left: auto;
            width: auto;
        }
        &--about-us {
            object-fit: contain;
            background-color: #f4f4f2;
        }

        @media screen and (max-width: 1920px) {
            &--stories {
                left: auto;
                width: auto;
            }
        }
        @media screen and (max-width: 1250px) {
            &--career {
                left: auto;
                width: auto;
            }
        }
    }
    @media screen and (max-width: 768px) {
        max-height: 250px;

        &--product,
        &--about-us,
        &--stories,
        &--career {
            @include imgCover();
        }
        &--stories,
        &--career {
            left: auto;
            width: auto;
        }
    }
    @media screen and (max-width: 425px) {
        max-height: 210px;
    }
}

.hero-subpage__text-wrapper {
    padding: 70px;
    background-color: #D9D9FF;

    @media screen and (min-width: 769px) {
        position: absolute;
        top: 70px;
        right: 50%;
        left: 0;
        display: flex;
        min-height: 100%;

        @media screen and (max-width: 1280px) {
            top: 150px;
            min-height: 80%;
        }
    }

    @media screen and (max-width: 1300px) {
        padding-top: 60px;
        padding-left: 45px;
    }
    @media screen and (max-width: 1200px) {
        padding: 45px;
    }
    @media screen and (max-width: 768px) {
        padding: 40px 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 40px 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 35px 20px;
    }
}

.hero-subpage__inner-text-wrapper {
    max-width: 510px;
    width: 100%;

    @media screen and (min-width: 769px) {
        margin-left: auto;
    }
}

.hero-subpage__heading {

}

.hero-subpage__subtitle {

}