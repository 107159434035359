
.pdf-banners-wrapper {

    @media screen and (min-width: 768px) {
        display: flex;
        margin: -8px;
    }
    @media screen and (max-width: 767px) {

    }
}

.pdf-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 435px;
    background: $color-blue url("../web_images/bg_banner-cta-wp.jpg") center no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
        margin: 8px;
        width: calc(50% - 16px);
    }

    @media screen and (max-width: 1280px) {
        height: 400px;
    }
    @media screen and (max-width: 1024px) {
        height: 365px;
    }
    @media screen and (max-width: 900px) {
        height: 340px;
    }
    @media screen and (max-width: 767px) {
        height: 270px;

        & + & {
            margin-top: 16px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: .5;
        @include transition-custom(opacity);
    }
    &:hover:before {
        opacity: 0;
    }
}

.pdf-banner__inner {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-banner__ico {
    width: 92px;
    height: 120px;
    background: url("../web_images/ico_download-pdf-white.svg") center no-repeat;
    background-size: contain;

    @media screen and (max-width: 1200px) {
        height: 100px;
    }
    @media screen and (max-width: 767px) {
        height: 90px;
    }
}

.pdf-banner__heading {
    margin-top: 30px;
    line-height: (55/40);
    text-align: center;
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();
    color: #fff;

    @media screen and (max-width: 1200px) {
        font-size: 28px;
    }
    @media screen and (max-width: 900px) {
        margin-top: 20px;
        font-size: 24px;
    }
}

.pdf-banner__cta-wrapper {
    margin-top: 30px;

    @media screen and (max-width: 900px) {
        margin-top: 20px;
    }
}

.pdf-banner__cta {

}