
/* Opener */

.newsletter-opener {
    z-index: 400;
    position: fixed;
    top: 401px;
    right: 0;
    margin-right: -44px;
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    font-size: 15px;
    color: #fff;
    @include transform(rotate(-90deg));
    @include box-shadow(0px 3px 6px #00000029);

    @media screen and (max-width: 1280px) {
        top: 371px;
    }
    @media screen and (max-width: 768px) {
        top: 231px;
    }
    @media screen and (max-width: 425px) {
        top: 191px;
    }
    @media screen and (max-height: 600px) {
        top: 50vh;
    }

    &.hidden {
        @include transform(rotate(-90deg) translateY(100%));
    }

    &.animate {
        @include animation-full(newsletter-opener-bounce 10s infinite ease);
    }

    &:before {
        @include before-after(0,0,100%,200%);
        z-index: -1;
        background-color: $color-blue;
        @include transition(background-color .3s ease-out);
    }
    &:hover:before {
        background-color: #000;
    }
}

@include keyframes(newsletter-opener-bounce)
{
    0% { transform: rotate(-90deg) translateY(0); }
    84% { transform: rotate(-90deg) translateY(0); }
    89% { transform: rotate(-90deg) translateY(-25px); }
    91% { transform: rotate(-90deg) translateY(-25px); }
    93% { transform: rotate(-90deg) translateY(-3px); }
    95% { transform: rotate(-90deg) translateY(-7px); }
    97% { transform: rotate(-90deg) translateY(-1px); }
    98% { transform: rotate(-90deg) translateY(-3px); }
    100% { transform: rotate(-90deg) translateY(0); }
}