
.stories {
    position: relative;

    .drag {
        display: none;
    }
}

.stories__link {

    @media screen and (min-width: 901px) {
        display: flex;
        align-items: center;

        &--reversed {
            flex-direction: row-reverse;
        }
    }
    @media screen and (max-width: 900px) {
        display: block;
        max-width: 375px;
        margin: 0 auto;
    }
}

.stories__img-wrapper {
    flex-shrink: 0;
    position: relative;
    //width: 389px;
    width: 33.68%;
    padding-top: 37.057%;

    @media screen and (max-width: 900px) {
        width: 100%;
        padding-top: 110.027%;
    }
}

.stories__img {
    @include imgCover();
    @include transition(all .3s cubic-bezier(.37,.7,.31,.98));
    opacity: 0;

    &.loaded.animate {
        opacity: 1;
    }
}

.stories__text-wrapper {
    position: relative;

    @media screen and (min-width: 901px) {
        width: 50%;
        margin-left: 160px;

        @media screen and (max-width: 1280px) {
            margin-left: 10vw;
        }

        &--reversed {
            margin-right: 160px;

            @media screen and (max-width: 1280px) {
                margin-right: 10vw;
            }
            @media screen and (max-width: 1024px) {
                margin-left: 0;
            }
        }
    }
}

.stories__no {
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 12px;

    @media screen and (min-width: 601px) {
        position: absolute;
        top: 10px;
        left: -30px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 20px;
    }
}

.stories__heading {
    max-width: 475px;
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();

    @media screen and (max-width: 1024px) {
        max-width: 420px;
    }
    @media screen and (max-width: 900px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
}

.stories__para {
    margin-top: 50px;
    max-width: 390px;
    line-height: (25/15);

    @media screen and (max-width: 1280px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 1023px) {
        margin-top: 20px;
    }
}

.stories__cta {

}



/* slideshow */

.slideshow-wrapper {
    margin-top: 40px;
}

.stories__img-wrapper--slideshow,
.stories__text-wrapper--slideshow {
    opacity: 0;
    @include transition(all .7s cubic-bezier(0.43, 0.38, 0.05, 0.95));

    .stories__link.active & {
        opacity: 1;
    }

    @media screen and (min-width: 901px) {
        @include transform(translateY(30px));

        .stories__link.active & {
            opacity: 1;
            @include transform(translateY(0));
        }
    }
    @media screen and (max-width: 900px) {
        @include transform(translateX(-30px));

        .stories__link.active & {
            opacity: 1;
            @include transform(translateX(0));
        }
    }
}
.stories__text-wrapper--slideshow {
    @include transition-delay(.2s);
}

.slideshow {
    opacity: 1 !important;
}

.slick-track {
    display: flex;
    height: 100%;
}

.slick-slider {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
}

.slick-list {
    overflow: hidden;
    height: 100%;
}

.slick-arrow {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 0;
    background: url("../web_images/ico_slide-arrow.svg") center no-repeat;
    background-size: 12px 22px;

    @media screen and (max-width: 540px) {
        bottom: -63px;
    }
}

.slick-prev {
    left: -100px;

    @media screen and (max-width: 900px) {
        left: 0;
    }
    @media screen and (max-width: 600px) {
        left: -20px;
    }

    @media screen and (min-width: 769px) {
        &:hover {
            @include transform(translateX(-10px));
        }
    }
}

.slick-next {
    right: -100px;
    @include transform(scale(-1));

    @media screen and (max-width: 900px) {
        right: 0;
    }
    @media screen and (max-width: 600px) {
        right: -20px;
    }

    @media screen and (min-width: 769px) {
        &:hover {
            @include transform(scale(-1) translateX(-10px));
        }
    }
}


.slideshow-progress {
    display: flex;
    margin-top: 50px;

    @media screen and (max-width: 900px) {
        max-width: 375px;
        margin: 40px auto 0;
    }
    @media screen and (max-width: 500px) {
        padding: 0 25px;
    }
}

.slideshow-progress__step {
    overflow: hidden;
    width: 100%;
    height: 3px;
    background-color: #E5E5E5;

    &:hover {
        background-color: #cccccc;
    }

    & + & {
        margin-left: 10px;
    }
}

.slideshow-progress__step-cur-time {
    width: 0;
    height: 100%;
    background-color: $color-blue;
}