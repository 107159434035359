

.cf {
    margin-top: 40px;
    width: 100%;
}

.cf__columns {

    @media screen and (min-width: 481px) {
        display: flex;
    }
}

.cf__column {
    @media screen and (min-width: 481px) {
        &:nth-child(1) {
            width: 66%;
        }
        &:nth-child(2) {
            display: flex;

            @media screen and (min-width: 551px) {
                width: 34%;
            }
        }
    }
}


/* vstupy formu */

.cf__input-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    & + & {
        margin-top: 17px;
    }
}

.cf__input-wrapper__spacer {
    flex-shrink: 0;

    @media screen and (min-width: 768px) {
        width: 20px;
    }
    @media screen and (max-width: 767px) {
        height: 17px;
    }
}

.cf__label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 15px;
    @include transition-all();
    @include transform-origin(center left);

    .cf__select.active + .errors-container + &,
    .cf__input:not(:placeholder-shown) + .errors-container + &,
    .cf__textarea:not(:placeholder-shown) + .errors-container + & {
        @include transform(translateY(-19px) scale(.7));
    }
    .cf__input:focus + .errors-container + &,
    .cf__textarea:focus + .errors-container + & {
        color: $color-blue;
        @include transform(translateY(-19px) scale(.7));
    }
}

.cf__input,
.cf__textarea,
.cf__select {
    padding: 8px 0;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    background-color: transparent;
    border-bottom: 1px solid #000;

    &:hover,
    &:focus {
        border-bottom-color: $color-blue;
    }

    @include placeholder() {
        color: transparent;
    };
}
.cf__textarea {
    resize: none;
}


/* validace */

.errors-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: initial;
    padding: 4px 5px;
    font-size: 12px;
    color: #fff;
    background-color: #ca2229;
    opacity: 0;
    pointer-events: none;
    @include transform(translateY(-5px));
    @include transition(none);
}
.not-validated + .errors-container {
    opacity: 1;
    @include transform(translateY(0px));
    @include transition(all 0.2s ease-out);
}

.not-validated + .cf__gdpr-text {
    color: #ca2229;
}

.cf .not-validated {
    border-color: #ca2229;

    &:focus {
        border-color: #ca2229;
    }
    &:hover {
        border-color: #ca2229;
    }
}


/* gdpr & req hint */

.cf__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    font-size: 12px;
}

.cf__gdpr-label {
    display: flex;
    align-items: center;

    & + & {
        margin-top: 10px;
    }
}

.cf__gdpr-checkbox {
    margin-right: 5px;
}

.cf__gdpr-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

/* recaptcha */

.cf__recaptcha {
    margin-top: 10px;
    opacity: .5;
}


/* zapati formu */

.cf__submit-outer-wrapper {
    margin-top: auto;
    margin-left: auto;
    display: flex;
}

.cf__submit-wrapper {
    position: relative;

    @media screen and (max-width: 550px) {
        margin-left: 35px;
    }
    @media screen and (max-width: 480px) {
        margin-top: 30px;
        margin-left: 0;
    }
}

.cf__submit-shadow {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
    padding: 0 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: $color-blue;
    @include transition-all();

    .cf__submit-wrapper:hover & {
        background-color: #000;
    }
}


.cf-submit {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    color: transparent;
    background-color: transparent;
}

.cf-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue;

    &--light {
        background-color: #fff;
    }

    pointer-events: none;
    opacity: 0;

    .cf-submit.loading + & {
        pointer-events: auto;
        opacity: 1;
    }
}

.cf-loader {
    width: 25px;
    height: 25px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &--light {
        border-color: $color-blue;
        border-bottom-color: transparent;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}