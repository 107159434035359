
.video {
    position: relative;
    height: 75vh;

    @include transition-custom(height, .3s);

    &.active {
        height: calc(100vh - 70px);
    }
}

.video__content {
    padding: 0;
}

.videos__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video__heading {
    z-index: 1;
    position: absolute;
    top: calc(50% - 60px);
    left: 0;
    text-align: center;
    width: 100%;
    color: #fff;

    @include transition-custom(opacity, .4s);

    .video.active & {
        opacity: 0;
        pointer-events: none;
    }
}

.video__play {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url("../web_images/video_hp_placeholder.jpg") center no-repeat;
    background-size: cover;

    @include transition-custom(opacity, .4s);

    &--what-is-magmio {
        background-image: url("../web_images/video_hp_placeholder2.webp");
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
}

.video__play-btn {
    width: 205px;
    height: 40px;
    background: url("../web_images/ico_video-play.svg") center no-repeat;
    background-size: contain;
    pointer-events: none;

    @media screen and (max-width: 768px) {
        width: 200px;
    }
    @media screen and (max-width: 600px) {
        width: 190px;
    }
    @media screen and (max-width: 425px) {
        width: 180px;
    }
    @media screen and (max-width: 395px) {
        width: 170px;
    }

    @include transition(all .6s cubic-bezier(0.49, 0.43, 0.14, 0.95));
    @include transform(scale(1.0001));

    .video__play:hover & {
        @include transform(scale(1.25));
    }
}