
.hero {
    overflow: hidden;
    position: relative;
    background-color: $color-blue;
}

.hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center no-repeat;
    background-size: cover;

    @media screen and (min-width: 1921px) {
        .no-webp & {
            background-image: url("../web_images/hero_image_x2.jpg");
        }
        .webp & {
            background-image: url("../web_images/hero_image_x2.webp");
        }
    }
    @media screen and (min-width: 1200px) and (max-width: 1920px) {
        .no-webp & {
            background-image: url("../web_images/hero_image.jpg");
            @include retina-image("../web_images/hero_image_x2.jpg");
        }
        .webp & {
            background-image: url("../web_images/hero_image.webp");
            @include retina-image("../web_images/hero_image_x2.webp");
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        .no-webp & {
            background-image: url("../web_images/hero_image_tablet.jpg");
            @include retina-image("../web_images/hero_image_tablet_x2.jpg");
        }
        .webp & {
            background-image: url("../web_images/hero_image_tablet.webp");
            @include retina-image("../web_images/hero_image_tablet_x2.webp");
        }
    }
    @media screen and (max-width: 767px) {
        .no-webp & {
            background-image: url("../web_images/hero_image_mobile.jpg");
            @include retina-image("../web_images/hero_image_mobile_x2.jpg");
        }
        .webp & {
            background-image: url("../web_images/hero_image_mobile.webp");
            @include retina-image("../web_images/hero_image_mobile_x2.webp");
        }
    }

    @media screen and (min-width: 768px) {
        @include animation-full(hero 20s infinite ease-out);
    }
    @media screen and (max-width: 767px) {
        @include animation-full(hero 15s infinite ease-out);
    }
}

@include keyframes(hero) {
    100%,
    0% {
        @include transform(scale(1));
    }
    50% {
        @include transform(scale(1.05));
    }
}

.hero__content {
    position: relative;
    max-width: 1155px;
    padding: 150px 0;
    color: #fff;

    @media screen and (max-width: 1440px) {
        padding: 120px 0;
    }
    @media screen and (max-width: 1280px) {
        padding: 100px 0;
    }
}

.hero__heading {
    line-height: (80/75);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 75px;
    font-weight: 400;

    @media screen and (max-width: 1440px) {
        font-size: 70px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 65px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 60px;
    }
    @media screen and (max-width: 600px) {
        font-size: 50px;
    }
    @media screen and (max-width: 425px) {
        font-size: 45px;
    }
    @media screen and (max-width: 375px) {
        font-size: 40px;
    }
}

.hero__para {
    margin-top: 50px;
    line-height: (30/25);
    font-size: 25px;

    @media screen and (max-width: 1024px) {
        margin-top: 40px;
        font-size: 23px;
    }
    @media screen and (max-width: 768px) {
        font-size: 21px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
        font-size: 20px;
    }
    @media screen and (max-width: 425px) {
        font-size: 19px;
    }
    @media screen and (max-width: 375px) {
        font-size: 18px;
    }
}

.hero__cta {
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }
}