
.footer {
    height: 534px;

    @media screen and (max-width: 768px) {
        height: 496px;
    }
    @media screen and (max-width: 767px) {
        height: 525px;
    }
    @media screen and (max-width: 480px) {
        height: 505px;
    }
    @media screen and (max-width: 450px) {
        height: 533px;
    }
    @media screen and (max-width: 425px) {
        height: 501px;
    }
}

.footer__inner {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
}

.footer__content-wrapper {
    color: #fff;
    background-color: #000;
}

.footer__content {

}

.footer__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 47px;
    padding: 0;

    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 500px;
        height: auto;
        padding: 10px;
    }
    @media screen and (max-width: 540px) {
        max-width: 400px;
    }
    @media screen and (max-width: 450px) {
        max-width: 300px;
    }
}

.footer__link {
    font-size: 12px;
    color: #FFFFFF4D;

    &:hover {
        color: #fff;
    }

    @media screen and (max-width: 767px) {
        margin: 7px 13px;
    }
}



.social-media {
    margin-top: 80px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: 60px;
    }
    @media screen and (max-width: 480px) {
        margin-top: 50px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 40px;
    }
}

.social-media__link {
    width: 31px;
    height: 31px;
    background: #525252 center no-repeat;
    //background-size: contain;
    border-radius: 50%;

    @include transition-all();

    &:hover {
        @include transform(scale(1.25));
    }

    & + & {
        margin-left: 15px;
    }

    &--fb {
        background-image: url("../web_images/ico_soc-dark-fb.svg");
    }
    &--yt {
        background-image: url("../web_images/ico_soc-dark-yt.svg");
    }
    &--twitter {
        background-image: url("../web_images/ico_soc-dark-twitter.svg");
    }
    &--in {
        background-image: url("../web_images/ico_soc-dark-in.svg");
        background-position-y: 7px;
    }
}