
.download-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    @include box-shadow(0px 3px 6px #00000029);
    @include transition(all cubic-bezier(0.22, 0.61, 0.36, 1) .5s);
    @include transform(translateY(100%));

    &.active {
        @include transform(translateY(0));
    }
}

.download-panel__content {
    display: flex;
    align-items: center;
    padding: 15px 0;
}

.download-panel__btn {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 40px;
    color: #fff;
    font-weight: 700;
    background-color: $color-blue;

    &:hover {
        background-color: #000;
    }
}

.download-panel__info {
    margin-left: 20px;
}

.download-panel__info-number {

}

.download-panel__info-plural {

    &.hidden {
        display: none;
    }
}