
#flashes {
  pointer-events: none;
  z-index: 123456789;
  position: fixed;
  width: 400px;
  right: 15px;
  top: 110px;
  bottom: 170px;
  padding: 0 10px;
  overflow: hidden;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.flash {
  pointer-events: auto;
  position: relative;
  z-index: 1;
  opacity: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 1.1em 1.5em;
  width: 100%;
  color: #fff;
  font-size: 14px;
  background-color: #2ECC71;
  border-radius: 5px;

  @include box-shadow(0 4px 10px 0 rgba(0,0,0,0.2));
  @include transition-custom(background-color, .2s);
}
.flash.error {
  background-color: #dc3434;
}
.flash.warning {
  background-color: #F39C12;
}

.flash:hover {
  background-color: #30c76a;
}
.flash.error:hover {
  background-color: #cf302d;
}
.flash.warning:hover {
  background-color: #e88f13;
}

.flash .flash-type {
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 15px;
}

.flash .progressbar {
  z-index: -1;
  position: absolute;
  content: "";
  width: 0%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.04;
}

.flash .close {
  float: right;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  cursor: pointer;
}

@media screen and (max-width: 430px) {

  #flashes {
    width: 93.333%;
    left: 50%;
    top: 90px;
    @include transform(translateX(-50%));
  }

  .flash {
    font-size: 13px;
    padding: 0.8em 1.2em;
  }

  .flash .flash-type {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 13.5px;
  }

  .flash .close {
    padding-left: 20px;
  }
}