
.contact-big {
    margin-top: 40px;
    background-color: #F2F2F2;

    @media screen and (min-width: 1101px) {
        display: flex;
    }
    @media screen and (max-width: 1100px) {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
    }
}

.contact-big__img-wrapper {
    position: relative;
    background-color: $color-blue;

    @media screen and (min-width: 1101px) {
        flex-shrink: 0;
        width: 50%;
        padding-top: 34.527%;
    }
    @media screen and (max-width: 1100px) {
        padding-top: (34.527%*2);
    }
}

.contact-big__img {
    @include imgCover();
}

.contact-big__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 60px 80px;

    @media screen and (max-width: 1250px) {
        padding: 50px;
    }
    @media screen and (max-width: 900px) {
        padding: 40px;
    }
    @media screen and (max-width: 768px) {
        padding: 35px;
    }
    @media screen and (max-width: 700px) {
        padding: 30px 23px;
    }
    @media screen and (max-width: 425px) {
        padding-top: 25px;
    }
}

.contact-big__heading {
    line-height: 1.2;
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.contact-big__inner-text-wrapper {
    margin-top: 35px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 40px;

    @media screen and (max-width: 900px) {
        margin-top: 25px;
        grid-gap: 20px 30px;
    }
    @media screen and (max-width: 768px) {
        grid-gap: 15px 30px;
    }
    @media screen and (max-width: 700px) {
        margin-top: 20px;
    }
    @media screen and (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.contact-big__para {
    line-height: (22/15);
}

.contact-big__link {
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;

    &:hover {
        -webkit-text-decoration-color: #000;
        text-decoration-color: #000;
    }
}

.contact-big__social-media {
    display: flex;
    align-items: center;
}

.contact-big__social-media-link {
    width: 31px;
    height: 31px;
    background: center no-repeat;
    background-size: contain;
    border-radius: 50%;
    //opacity: .3;

    @include transition-all();

    &:hover {
        //opacity: 1;
        @include transform(scale(1.25));
    }

    & + & {
        margin-left: 15px;
    }

    &--fb {
        background-image: url("../web_images/ico_soc-light-fb.svg");
    }
    &--yt {
        background-image: url("../web_images/ico_soc-light-yt.svg");
    }
    &--twitter {
        background-image: url("../web_images/ico_soc-light-twitter.svg");
    }
    &--in {
        background-image: url("../web_images/ico_soc-light-in.svg");
    }
}

.contact-big__cta {

}