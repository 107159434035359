
.benefits-wrapper {
    margin-top: 40px;
}

.benefits-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -8px;

    @media screen and (max-width: 600px) {
        margin: -8px 0;
    }
}

.benefits {
    position: relative;
    margin: 8px;
    width: calc(33.33% - 16px);
    text-align: center;
    padding: 45px 25px 60px;
    background-color: #fff;
    border: 1px solid $color-blue;

    @media screen and (max-width: 900px) {
        padding: 35px 25px 40px;
    }
    @media screen and (max-width: 768px) {
        width: calc(50% - 16px);
    }
    @media screen and (max-width: 600px) {
        margin: 8px auto;
        width: 100%;
        max-width: 330px;
    }
    @media screen and (max-width: 425px) {
        padding: 30px 25px 35px;
    }

    .benefits-list--wide & {
        width: calc(25% - 16px);

        @media screen and (max-width: 1024px) {
            width: calc(33.33% - 16px);
        }
        @media screen and (max-width: 768px) {
            width: calc(50% - 16px);
        }
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
}

.benefits__heading {
    line-height: (25/20);
    font-weight: 700;
    @include font-size-20();
}

.benefits__text {
    margin-top: 35px;
    line-height: (25/15);

    @media screen and (max-width: 900px) {
        margin-top: 25px;
    }
    @media screen and (max-width: 425px) {
        margin-top: 20px;
    }
}