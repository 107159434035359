
.summary-wrapper {
    margin-top: 40px;
}

.summary-list {

}

.summary {
    position: relative;
    padding: 20px 65px;
    background-color: #fff;

    @media screen and (max-width: 600px) {
        padding: 20px 30px 20px 50px;
    }
    @media screen and (max-width: 425px) {
        padding: 17px 25px 17px 45px;
    }
    @media screen and (max-width: 425px) {
        padding-right: 20px;
    }

    & + & {
        border-top: 1px solid #f2f2f2;
    }

    .admin-actions.small {
        width: auto;
    }
}

.summary__text-wrapper {
    position: relative;
    line-height: (22/15);
    max-width: 600px;

    &:before {
        @include before-after(5px, -30px, 6px, 12px);
        background: url("../web_images/ico_li.svg") center no-repeat;
        background-size: contain;

        @media screen and (max-width: 600px) {
            left: -25px;
        }
        @media screen and (max-width: 425px) {
            left: -23px;
        }
    }
}