
.leader {
    margin-top: 50px;
    background-color: #fff;

    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 767px) {
        margin: 50px auto 0;
        max-width: 433px;
    }
}

.leader__img-wrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 67.207%;
    background-color: $color-blue;

    @media screen and (min-width: 768px) {
        flex-shrink: 0;
        width: 50%;
    }
    @media screen and (max-width: 425px) {
        padding-bottom: 90%;
    }
}

.leader__img {
    @include imgCover();

    @media screen and (max-width: 425px) {
        height: auto;
    }
}

.leader__text-wrapper {
    padding: 60px;

    @media screen and (max-width: 900px) {
        padding: 40px 50px;
    }
    @media screen and (min-width: 768px) {
        width: 50%;
    }
    @media screen and (max-width: 767px) {
        padding: 35px;
    }
    @media screen and (max-width: 500px) {
        padding: 30px 25px;
    }
}

.leader__heading {
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();
}

.leader__position {
    margin-top: 5px;
    line-height: (22/18);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 18px;
}

.leader__para {
    margin-top: 40px;
    line-height: (25/15);
    max-width: 280px;

    @media screen and (max-width: 900px) {
        margin-top: 25px;
    }
    @media screen and (max-width: 500px) {
        margin-top: 20px;
    }
}


/* Vypis */

.leaders {
    display: flex;
    flex-wrap: wrap;
    margin: 50px -13px -13px;

    @media screen and (max-width: 767px) {
        margin-top: 40px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }
    @media screen and (max-width: 425px) {
        margin: 30px 0 -17px;
    }
}

.leaders__item {
    margin: 13px;
    width: calc(50% - 26px);

    @media screen and (max-width: 425px) {
        margin: 17px 0;
        width: 100%;
    }
}

.leaders__img-wrapper {
    overflow: hidden;
    position: relative;
    padding-bottom: 138.575%;
    background-color: $color-blue;

    @media screen and (max-width: 425px) {
        padding-bottom: 90%;
    }
}

.leaders__img {
    @include imgCover();

    @media screen and (max-width: 425px) {
        height: auto;
    }
}

.leaders__text-wrapper {

}

.leaders__heading {
    margin-top: 20px;
    line-height: 1.2;
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 18px;

    @media screen and (max-width: 600px) {
        margin-top: 18px;
    }
}

.leaders__position {
    margin-top: 15px;

    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
}