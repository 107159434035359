
.contact {
    margin-top: 40px;
    background-color: #F2F2F2;

    @media screen and (min-width: 701px) {
        display: flex;
    }
    @media screen and (max-width: 700px) {
        max-width: 400px;
        margin-right: auto;
        margin-left: auto;
    }
}

.contact__img-wrapper {
    flex-shrink: 0;
    position: relative;
    background-color: $color-blue;

    @media screen and (min-width: 701px) {
        width: 50%;
        padding-top: 34.527%;
    }
    @media screen and (max-width: 700px) {
        padding-top: (34.527%*2);
    }
}

.contact__img {
    @include imgCover();
}

.contact__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 35px 50px;

    @media screen and (max-width: 768px) {
        padding: 30px 35px;
    }
    @media screen and (max-width: 700px) {
        padding: 30px 23px;
    }
    @media screen and (max-width: 425px) {
        padding-top: 25px;
    }
}

.contact__heading {
    line-height: 1.2;
    font-size: 18px;
    font-weight: 700;
}

.contact__para {
    margin-top: 15px;
    line-height: (17/15);

    & + & {
        margin-top: 15px;
    }
}

.contact__link {
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;

    &:hover {
        -webkit-text-decoration-color: #000;
        text-decoration-color: #000;
    }
}

.contact__cta {

}