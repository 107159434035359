
.projects-wrapper {
    margin: 40px auto 0;
    max-width: $max-layout-width-narrow;
}

.project {
    padding: 33px;
    background-color: #fff;
    position: relative;

    @media screen and (max-width: 600px) {
        padding: 25px;
    }
    @media screen and (max-width: 425px) {
        padding: 23px;
    }

    & + & {
        margin-top: 50px;

        @media screen and (max-width: 680px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 30px;
        }
    }
}

.project__heading {
    margin-top: 45px;
    line-height: (25/20);
    font-weight: 700;

    @media screen and (max-width: 768px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }

    @include font-size-20();

    &:first-child {
        margin-top: 0;
    }
}

.project__table {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        margin-top: 15px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10px;
    }
}

.project__table-row {
    padding: 12px 0 5px;
    border-bottom: 1px solid #000;

    @media screen and (min-width: 769px) {
        display: flex;
    }
}

.project__table-col {

    @media screen and (min-width: 769px) {
        width: 45%;

        &:last-child {
            width: 55%;
        }
    }
    @media screen and (max-width: 768px) {
        /*&:first-child {
            font-weight: 700;
        }*/
        &:last-child {
            margin-top: 5px;
        }
    }
}

.project__annotation {
    margin-top: 25px;
    line-height: (22/15);

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 15px;
    }
}

.project__logos {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 45px;
    padding-top: 20px;
    border-top: 1px solid #000;

    @media screen and (max-width: 768px) {
        margin-top: 35px;
    }
    @media screen and (max-width: 600px) {
        margin-top: 30px;
        gap: 20px 30px;
    }
}

.project__logo {
    display: flex;
    align-items: center;
    height: 55px;

    @media screen and (max-width: 600px) {
        height: 50px;
    }
    @media screen and (max-width: 425px) {
        height: 45px;
    }
    @media screen and (max-width: 375px) {
        height: 40px;
    }
}

.project__logo-img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
}