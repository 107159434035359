
.steps {
    margin-top: 50px;

    @media screen and (max-width: 425px) {
        margin-top: 40px;
    }
}

.steps__item {
    padding: 0 45px 0 75px;

    @media screen and (max-width: 1100px) {
        padding: 0 25px 0 55px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0;
        padding-left: 30px;
    }

    @media screen and (min-width: 800px) {
        display: flex;
    }
    @media screen and (max-width: 799px) {
        padding: 0 45px 0 75px;
    }
    @media screen and (max-width: 600px) {
        padding: 0;
        padding-left: 30px;
    }
    @media screen and (max-width: 394px) {
        padding-left: 0;
    }

    & + & {
        margin-top: 45px;
        padding-top: 45px;
        border-top: 1px solid #E5E5E5;

        @media screen and (max-width: 900px) {
            margin-top: 40px;
            padding-top: 40px;
        }
        @media screen and (max-width: 600px) {
            margin-top: 35px;
            padding-top: 35px;
        }
        @media screen and (max-width: 500px) {
            margin-top: 30px;
            padding-top: 30px;
        }
    }
}

.steps__heading-wrapper {
    position: relative;
    max-width: 510px;

    @media screen and (max-width: 1280px) {
        padding-right: 40px;
    }
    @media screen and (max-width: 799px) {
        max-width: 425px;
        padding-right: 0;
    }
}

.steps__no {
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 12px;

    @media screen and (min-width: 395px) {
        position: absolute;
        top: 10px;
        left: -30px;
    }
}

.steps__heading {
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    @include font-size-40();

    @media screen and (max-width: 394px) {
        margin-top: 5px;
    }
}

.steps__para {
    max-width: 380px;
    line-height: (25/15);

    @media screen and (min-width: 800px) {
        margin-left: auto;
    }
    @media screen and (max-width: 799px) {
        margin-top: 20px;
        max-width: none;
    }
}