                  
@font-face {
    font-family: 'Hrot Premium';
    src: url('fonts/HrotPremium-Regular.eot');
    src: url('fonts/HrotPremium-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/HrotPremium-Regular.woff2') format('woff2'),
    url('fonts/HrotPremium-Regular.woff') format('woff'),
    url('fonts/HrotPremium-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hrot Premium';
    src: url('fonts/HrotPremium-Bold.eot');
    src: url('fonts/HrotPremium-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/HrotPremium-Bold.woff2') format('woff2'),
    url('fonts/HrotPremium-Bold.woff') format('woff'),
    url('fonts/HrotPremium-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@import "mixins/rules.scss";
@import "common/reset.scss";
@import "common/flashes.scss";
@import "common/login-panel.scss";
@import "common/magnific-popup.scss";


.mfp-iframe-holder .mfp-content {
    width: 95%;
    max-width: calc(177.77vh - 141px);
}


@mixin imgCover()
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin input()
{
    height: 49px;
    padding: 0 22px;

    @media screen and (max-width: 425px) {
        height: 47px;
        padding: 0 18px;
    }
}

@mixin font-size-20()
{
    font-size: 20px;

    @media screen and (max-width: 768px) {
        font-size: 19px;
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
}

@mixin font-size-40()
{
    font-size: 40px;

    @media screen and (max-width: 1280px) {
        font-size: 36px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 34px;
    }
    @media screen and (max-width: 950px) {
        font-size: 32px;
    }
    @media screen and (max-width: 900px) {
        font-size: 30px;
    }
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
    @media screen and (max-width: 375px) {
        font-size: 26px;
    }
}

@mixin retina-image($image) {
    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        background-image: url($image);
    }
}


$header-height: 70px;
$max-layout-width: 1155px;
$max-layout-width-narrow: 866px;
$max-layout-width-narrower: 572px;
$color-blue: #0000FF;


::-moz-selection {
    color: #fff;
    background-color: $color-blue;
}

::selection {
    color: #fff;
    background-color: $color-blue;
}


body {
    color: #000;
    background-color: #4d4d4d;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}
body.scroll-disabled {
    overflow: hidden;
} 



/* Obsah webu */

.main-wrapper {
    z-index: 3;
    position: relative;
    padding-top: $header-height;
    background-color: #fff;
}

.content-wrapper {
    width: 100%;
    padding: 0 45px;
    background-color: transparent;

    @media screen and (max-width: 768px) {
        padding: 0 35px;
    }
    @media screen and (max-width: 425px) {
        padding: 0 25px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 20px;
    }

    &--white {
        background-color: #fff;
    }
    &--light-grey {
        background-color: #F2F2F2;
    }
    &--grey {
        background-color: #E5E5E5;
    }
    &--purple {
        background-color: #D9D9FF;
    }
    &--black {
        color: #fff;
        background-color: #000;
    }
    &--layer-1 {
        z-index: 2;
        position: relative;
    }
    &--layer-2 {
        z-index: 3;
        position: relative;
    }
    &--standalone {
        min-height: calc(100vh - #{$header-height});
    }
}

.content {
    max-width: $max-layout-width;
    margin: 0 auto;
    padding: 50px 0 80px;

    @media screen and (max-width: 768px) {
        padding: 50px 0 70px;
    }
    @media screen and (max-width: 425px) {
        padding: 50px 0 60px;
    }

    &--narrow {
        max-width: $max-layout-width-narrow;
    }
    &--narrower {
        max-width: $max-layout-width-narrower;
    }

    &--pt-0 {
        padding-top: 0;
    }

    &--pb-20 {
        padding-bottom: 20px;
    }
    &--pb-10 {
        padding-bottom: 10px;
    }
    &--pb-0 {
        padding-bottom: 0;
    }
}



.heading {
    line-height: (18/15);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
        font-size: 19px;
    }
    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
    @media screen and (max-width: 425px) {
        font-size: 17px;
    }
    @media screen and (max-width: 395px) {
        font-size: 16px;
    }

    &--center {
        text-align: center;
    }
}

.subtitle {
    margin-top: 30px;
    line-height: (55/40);
    font-family: 'Hrot Premium', Arial, Helvetica, sans-serif;
    font-size: 40px;
    @include font-size-40();

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }

    &--news br {
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
}

.para {
    text-align: center;
    line-height: (25/15);
    margin-top: 30px;
    font-size: 15px;

    @media screen and (max-width: 530px) {
        br {
            display: none;
        }
    }

    &--error-page {
        text-align: left;
    }
}

.cta-wrapper {

    &--center {
        text-align: center;
    }
    &--space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 550px) {
            justify-content: center;
        }
    }
    &--right {
        text-align: right;
    }
    &--mt-50 {
        margin-top: 50px;

        @media screen and (max-width: 768px) {
            margin-top: 45px;
        }
        @media screen and (max-width: 425px) {
            margin-top: 40px;
        }
    }
    &--mt-40 {
        margin-top: 40px;

        @media screen and (max-width: 1280px) {
            margin-top: 30px;
        }
        @media screen and (max-width: 1024px) {
            margin-top: 25px;
        }
        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }
    &--mt-25 {
        margin-top: 25px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }
    &--mt-10 {
        margin-top: 10px;
    }
}

.paginator {
    margin-top: 45px;
    text-align: center;
}

.cta,
.paginator__more-btn {
    position: relative;
    display: inline-flex;
    padding-right: 14px;
    font-size: 18px;
    font-weight: 700;
    color: $color-blue;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    -webkit-text-decoration-color: currentColor;
    text-decoration-color: currentColor;
    @include transition-all();

    &--medium {
        font-size: 18px;
    }
    &--small {
        font-size: 15px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 9px;
        height: 100%;
        background: url("../web_images/ico_cta-arrow.svg") center right no-repeat;
        background-size: contain;
        opacity: 0;
        @include transform(scale(1.33) translateX(3px));
        @include transition-all(.2s);
    }

    &:hover,
    .cta-parent:hover & {
        color: #000;
    }
    &:hover:after,
    .cta-parent:hover &:after {
        opacity: 1;
        @include transform(scale(1) translateX(0));
    }

    &--back {
        padding-left: 14px;
        padding-right: 0;

        &:after {
            left: 0;
            right: auto;
            background: url("../web_images/ico_cta-arrow.svg") center right no-repeat;
            @include transform(scale(-1.33) translateX(3px));
        }

        &:hover:after,
        .cta-parent:hover &:after {
            @include transform(scale(-1) translateX(0));
        }
    }

    &--white {
        color: #fff;

        &:after {
            background-image: url("../web_images/ico_cta-arrow-white.svg");
        }

        &:hover,
        .cta-parent:hover & {
            color: #fff;
        }
    }
}

.big-cta {
    display: inline-flex;
    align-items: center;
    text-align: center;
    height: 45px;
    padding: 0 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: $color-blue;

    &:hover {
        background-color: #000;
    }

    @media screen and (min-width: 425px) {
        margin: 0 30px;
    }

    @media screen and (max-width: 600px) {
        padding: 0 25px;
    }

    &--schedule {
        @media screen and (max-width: 570px) {
            margin-top: 20px;
        }
    }
    &--video {
        @media screen and (max-width: 570px) {
            margin-bottom: 20px;
        }
    }

    &.animate {
        animation: big-cta-pulse 1s;
    }
}

@keyframes big-cta-pulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.98);
    }
    75% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

/*.no-wrap {
    white-space: nowrap;
}*/



/**** Spolecne casti ****/

/* Zahlavi */
@import "custom/header.scss";

/* Zapati */
@import "custom/footer.scss";

/* Hero blok podstranky */
@import "custom/heroSubpage.scss";

/* Tlacitko pro otevreni newsletter formu */
@import "custom/newsletterOpener.scss";

/* Detail success stories a novinek */
@import "custom/post.scss";

@import "custom/partners.scss";

@import "custom/cookieFreePrivacyPolicy.scss";


/**** Homepage ****/

/* Hero block */
@import "custom/hero.scss";

/* Uvodni odstavec textu */
@import "custom/introPara.scss";

@import "custom/whyMagmio.scss";

@import "custom/video.scss";

@import "custom/benefits.scss";

@import "custom/contact.scss";

@import "custom/contactForm.scss";

@import "custom/contactForm2.scss";

@import "custom/newsletterForm.scss";

@import "custom/linkedin.scss";


/**** Product ****/

@import "custom/schema.scss";

@import "custom/features.scss";

@import "custom/steps.scss";

@import "custom/cards.scss";

@import "custom/wpBanner.scss";


/**** About us ****/

@import "custom/leaders.scss";

@import "custom/team.scss";

@import "custom/milestones.scss";


/**** Success stories ****/

@import "custom/stories.scss";

@import "custom/summary.scss";


/**** News & Media ****/

@import "custom/news.scss";

@import "custom/lastNews.scss";

@import "custom/filter.scss";


/**** Career ****/

@import "custom/vacancies.scss";

@import "custom/banner.scss";


/**** Contact ****/

@import "custom/contactBig.scss";


/**** Research ****/

@import "custom/projects.scss";


/**** TaC & White papers ****/

@import "custom/docs.scss";

@import "custom/docsDownloadPanel.scss";



.grecaptcha-badge { 
    visibility: hidden; 
}